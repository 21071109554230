/* eslint-disable */
import Switcher from './plugins/switcher';
import Swiper from 'swiper/swiper-bundle';

// Swiper.use([Navigation]);

// Build svg sprite
function requireAll(r) {
  r.keys().forEach(r);
}
requireAll(require.context('../assets/images/icons/sprite', true, /\.svg$/));

window.onload = () => {

  let _hsp = window._hsp = window._hsp || [];
  _hsp.push(['addPrivacyConsentListener', function(consent) {
    console.log(consent);
    const categoriesKeys = Object.keys(consent.categories);
    let {categories, previousCategories, allowed} = consent;

    if(allowed){
      // send all triggers
        categoriesKeys.forEach(cat => {
          dataLayer.push({'event': 'consent_allowed_'+cat});
        })
      }else {
        categoriesKeys.forEach(cat => {
          if(categories[cat]){
            dataLayer.push({'event': 'consent_allowed_'+cat});
          }else { //check if it's a removal or it was already denied
            dataLayer.push({'event': 'consent_denied_'+cat});
            if(previousCategories[cat] !== categories[cat]){
             //maybe remove cookies own cookies in the future
            }
          }
        })
    }
  }]);

  function isAnalysisRisky() {
    if(typeof(window.opticksAssestmentP) === "undefined") return true;
    return window.opticksAssestmentP;
  }

  /*
  document.querySelectorAll('.js-form-field').forEach(field => {

    function isAnalysisInvalid() {    
      // analysis check
      if(!isAnalysisRisky()){
        // this button is disabled by default
        const submitButton = document.querySelector("button[type=submit]");
        submitButton.disabled = false;
      }
    }
    
    isAnalysisInvalid();
  });
*/
  
  const swiper = new Swiper('.js-carousel', {
    loop: true,
    slidesPerView: 'auto',
    speed: 1000,
    effect: 'coverflow',
    centeredSlides: true,
    slidesOffsetBefore: 0,
    coverflowEffect: {
      rotate: 0,
      stretch: 0,
      depth: 0,
      modifier: 1,
      scale: 0.95,
      slideShadows: false
    },
    navigation: {
      nextEl: '.js-carousel-button-next',
      prevEl: '.js-carousel-button-prev'
    },
    breakpoints: {
      576: {
        slidesPerView: 3,
        coverflowEffect: {
          scale: 0.85,
        }
      }
    }
  });

  const switcher = new Switcher();

  const mainMenuSwitcher = new Switcher({
    container: '.js-main-menu',
    control: '.js-main-menu-control',
    content: '.js-main-menu-content',
    bodyActiveClass: 'main-menu-opened'
  });

  switcher.init();
  mainMenuSwitcher.init();

  document.querySelectorAll('.js-form-field').forEach(field => {
    function isEmptyField(fieldNode) {
      const val = fieldNode.value.trim();
      const label = document.querySelector(`label[for=${fieldNode.id}]`);

      if (val.length) {
        label.classList.add('form-field__label--small');
      } else {
        label.classList.remove('form-field__label--small');
      }
    }

    isEmptyField(field);

    field.addEventListener('blur', () => {
      isEmptyField(field);
    });
  });

  document.body.addEventListener('click', e => {
    const _target = e.target.closest('.js-home-protect-label');

    if (_target) {
      _target.classList.add('is-hidden');
      e.preventDefault();
      setTimeout(() => {
        _target.classList.remove('is-hidden');
      }, 3000);
    }
  });

  let btnDetectionWork = document.querySelector('#solutionDetectionBtn');
  let ecommerceBtn = document.querySelector('#ecommerceBtn');
  let billingBtn = document.querySelector('#billingBtn');

  if(btnDetectionWork) {
    btnDetectionWork.addEventListener('click', () => {
      handleWork('solution-detection-works');
    });
  }

  if(ecommerceBtn) {
    ecommerceBtn.addEventListener('click', () => {
      handleWork('ecommerce-channels');
    });
  }

  if(billingBtn) {
    billingBtn.addEventListener('click', () => {
      handleWork('billing-results');
    });
  }
};

function handleWork (id) {
  const scrollTarget = document.getElementById(id);
  const topOffset = 0;
  const elementPosition = scrollTarget.getBoundingClientRect().top;
  const offsetPosition = elementPosition - topOffset;

  window.scrollBy({
      top: offsetPosition,
      behavior: 'smooth'
  });
}
